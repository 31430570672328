$black: #171717;
$orange: #c15342;
$red: #E02020;
$pink: #B620E0;
$yellow: #fecb39;
//$lightblue: #6236FF;
$purple: #B620E0;
//$yellow: #F7B500;
$white: #ffffff;
$blue: #232856;


@import "scss/style";

.LoginView {
  position: relative;
  background: linear-gradient(45deg, $blue 64%, $white 64%, $white 66%, $yellow 66%);
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;

  .logo {
    position: absolute;
    top: 5%;
    right: 5%;
    width: 20%;
    height: auto;
    max-width: 50%;
    max-height: 50%;
  }

  .card {
    width: 350px;
    display: flex;
    background-color: $yellow;
    flex-direction: column;
    padding: 30px 15px;
    align-items: center;
    box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.5);

    > * {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .error {
      color: $red;
      width: 75%;
      text-align: center;
    }

    h1 {
      padding: 0;
      color: $blue;
      font-weight: bold;
    }

    img {
      width: 50px;
      height: 50px;
      border-radius: 10px;
      //margin-bottom: 40px;
    }

    .login-form {
        width: 80%;
      border: 1px solid blue;
      border-radius: 8px;

      input {
        width: 100%;
        border-radius: 8px;
        outline: none;
        border: none;
        padding: 8px;
      }
    }

    .login-btn {
      margin-top: 40px;
      border-radius: 80px;
      width: 60%;
      background: $blue;
      color: $yellow;
    }

  }

}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

header .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
  color: $blue;
}

header .trigger:hover {
  color: $yellow;
}

aside .logo {
  height: 64px;
  margin: 0;
  background: #fff;
  z-index: 9999;
  padding: 8px 0;
  overflow: hidden;

  > svg {
    fill: $yellow;
    height: 32px;
  }
}

.list-card {
  margin: 16px 0;
  padding: 8px;
  border: 1px solid $blue;
  background: $white;
  color: $blue;

  .anticon {
    color: $blue !important;
  }
  .ant-row {
    .ant-col {
      margin-bottom: 4px;
      font-variant: all-small-caps;
    }
    &:after {
      content: "";
      width: 100%;
      margin-bottom: 2px;
      background: #ffcc32;
      height: 2px;
    }
  }
}

.ant-layout-sider, .ant-menu-dark {
  background-color: $blue;
  color: $yellow;
}
.ant-menu-dark {
  margin-top: 8px;
}
.ant-menu-dark .ant-menu-item > a,
.ant-menu-dark .ant-menu-item > span,
.ant-menu-dark .ant-menu-item > i {
  color: $yellow;
}
.vertical-align-middle {
  vertical-align: middle;
}
.form-control {
  display: block;
  width: 100%;
  height: 32px;
  padding: 6px 12px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  border: 0;
  border-bottom: 1px solid #eee;
}
.form-group {
  margin-bottom: 15px;
  padding: 0 5px;
  display: flex;
  flex-direction: row;
}
.form-group label {
  color: #585858;
  margin-bottom: 3px;
  font-weight: bold;
  font-size: 13px;
}
.form-group > *:not(:first-child) {
  margin-left: 4px;
}
.unit {
  order: -1;
  padding-top: 8px;
}
.row {
  display: flex;
  flex-display: row;
  flex-wrap: wrap;
  width: 100%;
}
.checkbox > input {
  margin-right: 3px;
  width: 16px !important;
  height: 16px !important;
  vertical-align: middle;
}
.prefix-label {display: none}
.col-sm-1 {
  width: 8.33%;
}
.col-sm-2 {
  width: 16.66%;
}
.col-sm-3 {
  width: 25%;
}
.col-sm-4 {
  width: 33.33%;
}
.col-sm-5 {
  width: 41.66%;
}
.col-sm-6 {
  width: 50%;
}
.col-sm-7 {
  width: 58.33%;
}
.col-sm-8 {
  width: 66.66%;
}
.col-sm-9 {
  width: 75%;
}
.col-sm-10 {
  width: 83.33%;
}
.col-sm-11 {
  width: 91.66%;
}
.col-sm-12 {
  width: 100%;
}
.select2 {
  display: none;
}
.section-content {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin: 20px;
  padding: 20px;
}
.section-title {
  font-size: 24px;
  margin-bottom: 16px;
}
.dashboard-link {
  height: 80px;
  line-height: 80px;
  width: calc(100% - 40px);
  text-align: center;
  display: block;
  border: 1px solid #242856;
  background: #242856;
  margin: 8px 20px;
  color: #ffcc32;
  font-size: 16px;
  font-variant: all-small-caps;
  transition: transform 0.4s;

  &:hover,
  &:active {
    background: #ffcc32;
    color: #242856;
    transform: scale(1.05);
  }
}

.dashboard-link:hover, a.dashboard-link:active {
}.current-selections {
   position: fixed;
   bottom: 20px;
   right: 40px;
   width: 240px;
   max-width: 50%;
   height: 142px;
   background: #fff;
   padding: 8px;
   border: 4px solid #242856;
 }
.current-selections > p {
  font-feature-settings: "smcp", "c2sc";
  font-variant: all-small-caps;
  margin: -8px -8px 4px;
  padding: 0 8px;
  background: #242856;
  color: #ffcc32;
}
.current-selections > div {
  font-feature-settings: "smcp", "c2sc";
  font-variant: all-small-caps;
  color: #242856;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 26px;
}

.current-selections > div:nth-child(2n+1) {
  border-bottom: 2px solid #ffcc32;
  overflow: hidden;
}

.current-selections > i {
  top: -2px;
  right: -2px;
}

.current-selections-minimized {
  border: 4px solid #ffcc32;
  background: #242856;
  position: fixed;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  bottom: -40px;
  right: -40px;
}

.current-selections-minimized:after {
  content: "+";
  left: 4px;
  top: 10px;
}

.current-selections > i,
.current-selections-minimized:after{
  position: absolute;
  cursor: pointer;
  color: #ffcc32;
  font-size: 24px;
  line-height: 16px;
  display: block;
  width: 32px;
  height: 20px;
  background: transparent;
  text-align: center;
}
